<template>
  <div>
    <vs-prompt
      class="vsDialogSuccess"
      title="Éxito"
      accept-text="Aceptar"
      color="success"
      @close="close"
      @accept="acceptAlert"
      :active.sync="activeS"
    >
      <div class="prompt">
        <p v-for="(text,index) in questions" :key="index">{{text}}</p>
      </div>
    </vs-prompt>
  </div>
</template>

<script>
export default {
  name: 'PopupSuccess',
  props: {
    questions: {
      type: Array,
      required: true
    },
    active: Boolean
  },
  data () {
    return {
      activeS: false
    }
  },
  methods: {
    acceptAlert () {

    },
    close () {
      window.location.reload()
    }
  },
  mounted () {
    this.activeS = this.active
  },
  watch: {
    activeS (newVal) {
      this.$emit('update:active', newVal)
    }
  }
}
</script>

<style lang="scss">
.vsDialogSuccess {
  .vs-dialog-cancel-button {
    display: none;
  }
}
</style>